<template>
  <div class="field-error-container uk-text-danger">
    <ErrorMessage :name="name" />
  </div>
</template>
<style lang="scss" scoped>
.field-error-container {
  max-width: 100%;
  text-align: left;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { ErrorMessage } from 'vee-validate';
export default defineComponent({
  components: { ErrorMessage },
  props: {
    name: {
      type: String,
      required: true,
    },
  },
});
</script>
