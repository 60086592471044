import UIkit from "uikit";

export function useNotification() {
  // 表示位置
  // 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right'
  const position = "bottom-right";

  // エラー表示用
  const error = (message: string) =>
    UIkit.notification(message, { status: "danger", pos: position });

  // 成功処理用
  const success = (message: string) =>
    UIkit.notification(message, { status: "success", pos: position });

  return {
    error,
    success,
  };
}
