
import { defineComponent, reactive, nextTick } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { UserApi } from "../../autogen";
import FieldLabel from "../atoms/FieldLabel.vue";
import FieldError from "../atoms/FieldError.vue";
import Instruction from "../atoms/Instruction.vue";
import { useI18n } from "vue-i18n";
import { Instruction as InstructionType } from "../../types";
import { useTerms } from "../compositions/useTerms";

type State = {
  instruction?: InstructionType;
  confirmed: boolean;
  submitted: boolean;
};
export default defineComponent({
  components: { FieldLabel, FieldError, Instruction },
  setup() {
    const state: State = reactive({ confirmed: false, submitted: false });
    const { t } = useI18n();
    const { handleSubmit, isSubmitting } = useForm<{
      email: string;
      password: string;
      belongs_to: string;
      username: string;
      first_name?: string;
      last_name?: string;
      address?: string;
    }>({
      validationSchema: yup.object({
        username: yup
          .string()
          .required(t("required", { field: "ユーザ名" }))
          .matches(/^[a-zA-Z0-9]{5,}$/, t("username")),
        email: yup
          .string()
          .email(t("email"))
          .required(t("required", { field: "メールアドレス" }))
          .matches(/^[^+]*$/, t("email")),
        password: yup
          .string()
          .required(t("required", { field: "パスワード" }))
          .matches(
            /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,255}$/,
            t("password")
          ),
        belongs_to: yup
          .string()
          .required(t("required", { field: "会社名・学校名" })),
        first_name: yup.string(),
        last_name: yup.string(),
        address: yup.string(),
      }),
    });
    const { value: username } = useField("username");
    const { value: email } = useField("email");
    const { value: password } = useField("password");
    const { value: first_name } = useField("first_name");
    const { value: last_name } = useField("last_name");
    const { value: belongs_to } = useField("belongs_to");
    const { value: address } = useField("address");
    function instruct(confirmed: boolean, instruction?: InstructionType) {
      window.scrollTo(0, 0);
      state.confirmed = confirmed;
      state.instruction = instruction;
    }
    const onSubmit = handleSubmit(async (values) => {
      if (!state.confirmed) {
        instruct(true, {
          level: "confirm",
          message: "登録内容を確認してください",
          timeout: false,
        });
        window.scrollTo(0, 0);
      } else {
        state.instruction = undefined;
        nextTick(async () => {
          try {
            await new UserApi().register({ registerRequest: values });
            state.instruction = {
              level: "info",
              message: "入力されたメールアドレスに認証メールを送信しました",
            };
            state.submitted = true;
          } catch (err) {
            if (err.response && err.response.status === 409) {
              const message = err.response.data.detail
                .map((d: { msg: string }) => t(d.msg))
                .join("<br/>");
              state.instruction = { level: "alert", message };
            } else {
              state.instruction = { level: "alert", message: err.message };
            }
          }
          window.scrollTo(0, 0);
        });
      }
    });
    const onAlertClose = () => {
      state.instruction = undefined;
    };
    const onBackButtonClick = () => {
      instruct(false);
    };
    const terms = useTerms();
    return {
      termsText: terms.text,
      state,
      onSubmit,
      isSubmitting,
      onAlertClose,
      username,
      email,
      password,
      first_name,
      last_name,
      belongs_to,
      address,
      onBackButtonClick,
    };
  },
});
