<template v-slot:unauthenticated>
  <Page>
    <div v-if="state.instruction">
      <Instruction
        @close="onAlertClose"
        position="relative"
        :level="state.instruction.level"
      >
        <p>{{ state.instruction.message }}</p>
      </Instruction>
    </div>
    <form @submit="onSubmit">
      <fieldset class="uk-fieldset">
        <FieldLabel title="ユーザ名" />
        <input
          class="uk-input"
          type="text"
          :readonly="true"
          name="username"
          v-model="username"
          v-clear-instruction
          :disabled="true"
        />
        <FieldError name="username" />
        <FieldLabel title="メールアドレス" :required="true" />
        <input
          class="uk-input"
          type="text"
          name="email"
          v-model="email"
          v-clear-instruction
          :disabled="isEnterprise === true"
        />
        <FieldError name="email" />
        <FieldLabel title="パスワード" :required="true" />
        <input
          class="uk-input"
          type="password"
          name="password"
          v-model="password"
          v-clear-instruction
        />
        <FieldError name="password" />
        <div class="col2">
          <div>
            <FieldLabel title="姓" />
            <input
              class="uk-input"
              type="text"
              name="last_name"
              v-model="last_name"
              v-clear-instruction
            />
            <FieldError name="last_name" />
          </div>
          <div>
            <FieldLabel title="名" />
            <input
              class="uk-input"
              type="text"
              name="first_name"
              v-model="first_name"
              v-clear-instruction
            />
            <FieldError name="first_name" />
          </div>
        </div>
        <FieldLabel title="会社名・学校名" :required="true" />
        <input
          class="uk-input"
          type="text"
          name="belongs_to"
          v-model="belongs_to"
          v-clear-instruction
          :disabled="isOrganizationUser === true"
        />
        <FieldError name="belongs_to" />
        <FieldLabel title="住所" />
        <input
          class="uk-input"
          type="text"
          name="address"
          v-model="address"
          v-clear-instruction
        />
        <FieldError name="address" />
      </fieldset>
      <div class="uk-card-footer">
        <button
          type="submit"
          :disabled="isSubmitting"
          class="uk-button uk-button-primary uk-margin-small-top"
          v-clear-instruction
        >
          更新
        </button>
      </div>
    </form>
  </Page>
</template>
<style lang="scss" scoped>
@include form-input;
@include form-col2;
.uk-card-footer {
  text-align: right;
  button {
    min-width: 150px;
  }
}
@media screen and (max-width: 480px) {
  .page-container {
    margin-top: $spacing-ex-small;
  }
}
</style>
<script lang="ts">
import { defineComponent, onBeforeMount, onBeforeUpdate, ref } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { EnterpriseApi, MyApi } from "../../autogen";
import FieldLabel from "../atoms/FieldLabel.vue";
import FieldError from "../atoms/FieldError.vue";
import Page from "../atoms/Page.vue";
import Instruction from "../atoms/Instruction.vue";
import { useI18n } from "vue-i18n";
import Store from "../../store";
import { LocationQuery, onBeforeRouteUpdate, useRoute } from "vue-router";
import { Jwt, parseJwt } from "../../helpers/jwt";
import { useNotification } from "../compositions/useNotification";

type State = {
  instruction?: { level: string; message: string };
};
export default defineComponent({
  components: { FieldLabel, FieldError, Page, Instruction },
  setup() {
    const isEnterprise = ref<boolean>(false);
    const isOrganizationUser = ref<boolean>(false);
    const state: State = {};
    const myApi = new MyApi();
    const enterpriseApi = new EnterpriseApi();
    const { t } = useI18n();

    const { handleSubmit, isSubmitting, resetForm } = useForm<{
      email?: string;
      password?: string;
      belongs_to?: string;
      address?: string;
      first_name?: string;
      last_name?: string;
    }>({
      validationSchema: yup.object({
        address: yup.string(),
        first_name: yup.string(),
        last_name: yup.string(),
        email: yup
          .string()
          .email(t("email"))
          .required(t("required", { field: "メールアドレス" }))
          .matches(/^[^+]*$/, t("email")),
        password: yup.string().when("$password", {
          is: () => {
            return passwordField.dirty;
          },
          then: (schema) => {
            return schema.matches(
              /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,255}$/,
              t("password")
            );
          },
        }),
        belongs_to: yup
          .string()
          .required(t("required", { field: "会社名・学校名" })),
      }),
    });

    // プロファイルの取得と各入力への設定を行う関数
    // onBeforeMountとonBeforeUpdate時にそれぞれ呼び出される
    const getProfile = async (query: LocationQuery) => {
      isEnterprise.value = query.mode === "enterprise";

      // エンタープライズモード
      // クエリパラメータにエンタープライズモードが指定されており、
      // 且つユーザーIDが指定されている場合はエンタープライズ用のAPIへリクエストする
      let response = null;
      if (isEnterprise.value) {
        if (query.userId && !Array.isArray(query.userId)) {
          const userId = parseInt(query.userId);
          response = await enterpriseApi.getEnterpriseProfile({ userId });
        } else {
          useNotification().error("パラメータが不正です");
        }
      }
      // 非エンタープライズモード
      // エンタープライズモードの指定がない場合は、
      // ユーザー自身のプロフィールを取得する
      else {
        response = await myApi.getMyProfile({});
      }

      // フォームへ値を設定
      if (response) {
        isOrganizationUser.value = !!response.data.organization;
        const initProfile = response.data.profile;
        if (isOrganizationUser.value) initProfile.belongs_to = response.data.organization;
        resetForm({
          values: Object.assign(
            { password: "**********" },
            initProfile
          ),
        });
      }
    };

    // 画面表示時の処理を設定
    onBeforeMount(async () => {
      await getProfile(useRoute().query);
    });
    // 画面更新時の処理の設定
    // これを設定しておかないとエンタープライズプロフィール画面から
    // 個人プロフィール画面へ移動した時に表示項目の更新が行われない
    onBeforeRouteUpdate((to, from, next) => {
      getProfile(to.query);
      next();
    });

    const { value: username } = useField("username");
    const { value: email, meta: emailField } = useField("email");
    const { value: password, meta: passwordField } = useField("password");
    const { value: first_name } = useField("first_name");
    const { value: last_name } = useField("last_name");
    const { value: belongs_to } = useField("belongs_to");
    const { value: address } = useField("address");

    const route = useRoute();
    const onSubmit = handleSubmit(async (values) => {
      // エンタープライズモードか確認
      const query = route.query;
      const isEnterprise = query.mode ? query.mode === "enterprise" : false;

      try {
        const payload = Object.assign(values);
        if (!passwordField.dirty) {
          delete payload.password;
        }

        if (isEnterprise) {
          // エンタープライズモード時の処理
          //
          // ページ表示後にURLのアドレスを直接変更したとしても、
          // リクエストされるパラメータはロード時のパラメータに依存するため影響されない
          //
          // また、サーバー側でアドミンかつ、同じ組織に所属している事が更新の条件になっているため
          // 直接POSTMANなどでJSONを偽装されても対応可能
          //
          let userId: number | null = null;
          if (query.userId && !Array.isArray(query.userId)) {
            userId = parseInt(query.userId);
            await new EnterpriseApi().updateEnterpriseProfile({
              updateEnterpriseProfileRequest: {
                user_id: userId,
                email: values.email,
                password: values.password,
                belongs_to: values.belongs_to,
                address: values.address,
                last_name: values.last_name,
                first_name: values.first_name,
              },
            });
          } else {
            throw new Error("ユーザーID取得に失敗しました");
          }
        } else {
          await new MyApi().updateMyProfile({ updateMyProfileRequest: values });
        }
        // if (emailField.dirty) {
        //   state.instruction = {
        //     level: 'info',
        //     message: '入力されたメールアドレスに認証メールを送信しました',
        //   };
        //   Store.getInstance().removeAuth();
        // } else {
        state.instruction = { level: "info", message: "更新しました" };
        // }
        window.scrollTo(0, 0);
      } catch (err) {
        if (err.response && err.response.status === 409) {
          state.instruction = {
            level: "alert",
            message: t("email duplicated"),
          };
        } else {
          state.instruction = { level: "alert", message: err.message };
        }
      }
    });
    const onAlertClose = () => {
      state.instruction = undefined;
    };
    return {
      isEnterprise,
      isOrganizationUser,
      state,
      onSubmit,
      isSubmitting,
      onAlertClose,
      username,
      email,
      password,
      first_name,
      last_name,
      belongs_to,
      address,
    };
  },
});
</script>
