import { ref } from "vue";

const userId = ref<number | null>(null);
const isAdmin = ref<boolean>(false);

export function useApp() {
  return {
    userId,
    isAdmin,
  };
}
