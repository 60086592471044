import { createApp, Directive } from 'vue';
import App from './App.vue';
import router from './router';
import axios, { AxiosRequestConfig } from 'axios';
import qs from 'qs';

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
import 'uikit/dist/css/uikit.css';
import 'uikit/dist/css/uikit.min.css';
import 'loaders.css/loaders.min.css';
import i18n from './i18n';
import gtag from 'vue-gtag-next';
import { useGtag } from 'vue-gtag-next';
// eslint-disable-next-line @typescript-eslint/ban-types
(UIkit.use as (UIkit: object) => void)(Icons);

axios.interceptors.request.use((config: AxiosRequestConfig) => {
  const url = new URL(config.url || '');
  url.search = qs.stringify(qs.parse(url.search, { ignoreQueryPrefix: true }), {
    arrayFormat: 'comma',
    encode: false,
  });
  config.url = url.href;
  return config;
});
axios.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response.status === 401) {
      router.push('/login');
    } else {
      return Promise.reject(err);
    }
  }
);

const onInputFieldChangeListener = () => {
  const ukAlert = document.getElementsByClassName('uk-alert');
  if (ukAlert) {
    UIkit.alert(ukAlert[0]).close();
  }
};

const { event } = useGtag();
const gaTrackDirective = (() => {
  const state = {} as { onEvent: (e: Event) => void };
  return {
    mounted(
      el: HTMLElement,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      binding: { value: { action: string; params?: { [key: string]: any } } }
    ) {
      const tag = el.tagName.toLowerCase();
      state.onEvent = (_e) => {
        if (process.env.NODE_ENV === 'local') {
          console.log(`ga-track: ${JSON.stringify(binding.value)}`);
          return;
        }
        try {
          event(binding.value.action, binding.value.params);
        } catch (err) {
          console.warn(err);
        }
      };
      if (tag === 'button' || tag === 'a') {
        el.addEventListener('click', state.onEvent);
      } else if (tag === 'form') {
        el.addEventListener('submit', state.onEvent);
      }
    },
    unmounted(el: HTMLElement) {
      el.removeEventListener('click', state.onEvent);
      el.removeEventListener('submit', state.onEvent);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as Directive<any>;
})();
const app = createApp(App).use(i18n).use(router);
if (process.env.NODE_ENV !== 'local') {
  app.use(gtag, {
    property: {
      id: 'UA-187318953-1',
    },
  });
}
app
  .directive('ga-track', gaTrackDirective)
  .directive('clear-instruction', {
    mounted(el: HTMLElement) {
      const tag = el.tagName.toLowerCase();
      if (tag === 'input' || tag === 'select' || tag === 'textarea') {
        el.addEventListener('change', onInputFieldChangeListener);
      } else if (tag === 'button' || tag === 'a') {
        el.addEventListener('click', onInputFieldChangeListener);
      }
    },
  })
  .mount('#app');
