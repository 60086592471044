import { EnterpriseApi, GetMyUsage, MyApi } from "@/autogen";
import { AxiosResponse } from "axios";
import { ref } from "vue";
import { useNotification } from "./useNotification";

// static object
// 論文のリスト
// keyが論文のレコードIDになっている
const list = ref<GetMyUsage[]>([]);

// 組織ユーザかどうか
const isOrganizationUser = ref<boolean>(false);

// 同組織の全ユーザの履歴を取得するかどうか
const isRequestAll = ref<boolean>(true);

// 論文読み込み時に期待される論文の数
// ロード時に、この数を下回ると、読み込み用のボタンを非表示にする
const EXPECTED_LOAD_ITEM_COUNT = 10;

// eslint-disable-next-line
export function useUsage() {
  return {
    list,
    isOrganizationUser,
    isRequestAll,
    load,
    getCurrentPage,
    open,
    clear,
  };
}

/**
 * 現在のページ番号を返却する
 * @returns 現在のページ番号（0 インデックス）
 */
function getCurrentPage(): number {
  return Math.floor(list.value.length / EXPECTED_LOAD_ITEM_COUNT) - 1;
}

/**
 * 魯文データリストのクリアを行うための関数
 *
 */
function clear() {
  list.value = [];
}

/**
 * 論文データを取得するための関数
 * @param page ページ番号
 * @returns なし
 */
async function load(
  mode: "my" | "enterprise",
  userId: number | null,
  page: number
): Promise<void> {
  // エンタープライズモード時の処理
  let usages = null;
  if (mode === "enterprise") {
    if (userId) usages = await _getEnterpriseUsages(userId, page);
    else useNotification().error("パラメータが不正です");
  }
  // 非エンタープライズモード時の処理
  else if (mode === "my") {
    usages = await _getMyUsages(page);
  }
  // 不明のモード時の処理
  else {
    useNotification().error("モードが不正です");
    return;
  }

  // 論文をリストに追加
  if (usages !== null) {
    let data = usages.data;
    const addedCount = _addList(data);
    _sortList();

    // 取得成功時のNotificationを表示
    useNotification().success(`${addedCount} 件の履歴を取得しました`);
  }
}

/**
 * 論文リストに追加を行うための関数
 * @param receivedUsageList 受信した論文の配列
 * @returns 追加された要素の数
 */
function _addList(receivedUsageList: GetMyUsage[]): number {
  // 現在のリストに存在するIDを取得
  const existsUsageIdList = list.value.map((usage) => usage.id);

  // まだ受信していなかった論文IDのインデックスを取得
  const unregisteredUsageList = receivedUsageList.filter(
    (usage) => existsUsageIdList.indexOf(usage.id) == -1
  );

  // 未受信だった論文をリストへ追加
  let counter = 0;
  for (const index in unregisteredUsageList) {
    const usage = unregisteredUsageList[index];
    list.value.push(usage);
    ++counter;
  }
  return counter;
}

/**
 * リストのソートを行うための関数
 * @returns なし
 */
function _sortList(): void {
  list.value.map((usage) => usage.uploaded_at);
  list.value = list.value.sort(function (usageA, usageB) {
    if (usageA.uploaded_at === undefined) return -1;
    if (usageB.uploaded_at === undefined) return -1;
    return usageA.uploaded_at < usageB.uploaded_at ? 1 : -1;
  });
}

/**
 * 論文情報取得用の関数
 * @param page ページ番号
 * @returns 論文情報
 */
async function _getMyUsages(
  page: number
): Promise<AxiosResponse<GetMyUsage[]> | null> {
  try {
    const api = new MyApi();
    const all = isOrganizationUser && isRequestAll.value;
    const response = await api.getMyUsages({ page: page, ...(all ? {all: all} : {}) });
    if (response.status !== 200) throw new Error();
    return response;
  } catch {
    useNotification().error("論文の取得に失敗しました");
    return null;
  }
}

/**
 * 論文情報取得用の関数
 * @param usageId
 */
async function _getEnterpriseUsages(
  userId: number,
  page: number
): Promise<AxiosResponse<GetMyUsage[]> | null> {
  try {
    const api = new EnterpriseApi();
    const response = await api.getEnterpriseUsages({ userId, page });
    if (response.status !== 200) throw new Error();
    return response;
  } catch {
    useNotification().error("論文の取得に失敗しました");
    return null;
  }
}

/**
 * 論文HTMLの表示を行うための関数
 * @param usageId 論文ID
 */
async function open(usageId: number) {
  // 署名付きURLを取得
  const presignedUrl = await _getPresignedUrl(usageId);
  if (presignedUrl !== null) {
    // 新しいウィンドウ(タブ)で開く
    window.open(presignedUrl);
  }
}

/**
 * 署名付きURLの取得を行うための関数
 * @param usageId 論文のID
 * @returns 署名付きURL
 */
async function _getPresignedUrl(usageId: number): Promise<string | null> {
  try {
    const api = new MyApi();
    const response = await api.getMyHtmlFileUrl({ usageId: usageId });
    if (response.status !== 200) throw new Error();
    return response.data;
  } catch (e) {
    useNotification().error("署名付きURLの取得に失敗しました");
    return null;
  }
}
