import { reactive } from '@vue/reactivity';

type State = {
  accessToken: string | null;
  auth: boolean;
};

export default class Store {
  private static _instance = new Store();
  static getInstance(): Store {
    return this._instance;
  }
  private state: State = reactive({
    accessToken: null,
    auth: false,
  });
  getAccessToken(): State['accessToken'] {
    const accessToken =
      this.state.accessToken || localStorage.getItem('accessToken');
    this.state.accessToken = accessToken;
    return accessToken;
  }
  setAccessToken(accessToken: string): void {
    this.state.accessToken = accessToken;
    localStorage.setItem('accessToken', accessToken);
  }
  getAuth(): State['auth'] {
    return this.state.auth;
  }
  setAuth(auth: boolean): void {
    this.state.auth = auth;
  }
  removeAuth(): void {
    this.state.auth = false;
    this.state.accessToken = null;
    localStorage.removeItem('accessToken');
  }
}
