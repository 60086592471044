
import { defineComponent, onBeforeMount } from "vue";
import UsageProgress from "../atoms/UsageProgress.vue";
import MembersTable from "../atoms/MembersTable.vue";
import { useEnterprise } from "../compositions/useEnterprise";

export default defineComponent({
  components: { MembersTable, UsageProgress },
  setup() {
    const enterprise = useEnterprise();

    // 初期化処理
    onBeforeMount(async () => {
      await enterprise.getMembers();
      await enterprise.getUsages();
    });

    return {
      enterprise,
    };
  },
});
