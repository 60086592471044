export function useTerms() {
  let text = getText();
  // text = text.replace(/(\r\n|\n|\r)/gm, "<br />");
  return {
    text,
  };
}

function getText(): string {
  return `
  この利用規約（以下，「本規約」といいます。）は，株式会社ファイマテクノロジー（以下，「当社」といいます。）がこのウェブサイト（以下、「本サイト」）上で提供するサービスである論文翻訳 AI1paper（以下，「本サービス」といいます。）の利用条件を定めるものです。本規約は、本サービスを利用する個人（以下「ユーザー」）および当社と本サービスの提供に係る契約（以下、「法人契約」）を締結する法人（以下「法人」）に対して適用されます。ユーザーおよび法人は、本サービスの利用をもって本規約を承諾したものとみなします。また、ユーザーが未成年の場合は親権者等法定代理人（以下「保護者」）の同意を得ることが必要になり、本サービスを利用した時点で保護者の同意があったものとみなします。


  第1条（利用規約の範囲及び変更）
  
  1項
  本規約は、本サービスの一切、当社がユーザーおよび法人に対して電子メール等で発信する情報等による通知も含めますがこれに限られません）について適用されます。
  
  2項
  当社は、必要と判断した場合には，ユーザーおよび法人に通知することなくいつでも本規約を変更することができるものとします。
  
  3項
  変更後の本規約の効力発生日以降に、ユーザーおよび法人が本サービスを利用したときは、ユーザーおよび法人は、本規約の変更に同意したものとみなします。本規約の変更に同意しない場合は、本サービスの利用を停止してください。当社は、本規約の改定又は変更によりユーザーおよび法人に生じたすべての損害について、当社の故意又は過失に起因する場合を除き、責任を負いません。
  
  4項
  当社とユーザーおよび法人間において本規約とは別にご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。本規約の規定が個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。ただし、プライバシーポリシーについては、この限りではありません。
  
  
  第2条（利用登録）
  
  1項
  ユーザーは、当社が定める手続きに従って本サービスを利用するために必要な情報を登録し、利用者アカウント（以下、「アカウント」といいます）を作成することで本サービスに登録するものとします。ただし、法人契約に基づく利用の場合は、法人契約で必要とされた情報について所定の手続きで登録するものとします。
  
  2項
  ユーザー及び法人はアカウントの作成にあたり、本規約を熟読し、その内容を承諾した上で、本サービスの登録をしたものとします。
  
  3項
  当社は、ユーザーおよび法人が下記事由に該当する場合には、その登録を拒否または取り消すことができます。また、原則として支払済み金額については一切返金をしないこととします。
  ・ユーザーまたは法人が実在しない場合
  ・登録情報に、虚偽、誤記、または記入漏れがあった場合
  ・利用料金の支払い能力がないと当社が判断した場合
  ・利用料金の支払いを滞った場合
  ・過去に当社により登録情報を削除されている場合
  ・重複した別アカウントにて本サービスの登録をしている場合
  ・暴力団、暴力団関係企業、総会屋もしくはこれらに準ずる者またはその構成員の場合またはその虞がある場合
  ・第6条に違反した場合
  ・その他、本サービスの利用者として当社が不適当であると判断した場合
  
  
  第3条（登録情報の変更）
  
  ユーザーおよび法人は登録情報の内容に変更があった場合、遅滞なく本サイトにて登録情報の変更手続きをするものとします。登録情報の変更を怠ったことにより生じたユーザーおよび法人の不利益、その他の負担に関して、当社は一切の責任を負わないものとします。
  
  
  第4条（登録情報の利用と取り扱い）
  
  1項
  当社は登録情報のうち「個人情報」に該当する情報について、当社が別途掲載する「プライバシーポリシー」に則って適切に取り扱うものとします。
  
  2項
  ユーザーおよび法人は登録した情報を厳重に管理するものとします。当社は、本サイトにて IDおよびパスワードの一致を確認することにより、当該アクセス者を利用者本人とみなします。
  
  3項
  ユーザーおよび法人は、アカウントを自己の為にのみ使用するものとし、アカウントを第三者に使用させてはなりません。また、第三者への譲渡、貸与等も行ってはなりません。
  
  4項
  ユーザーおよび法人による登録情報の失念、消失および登録情報の管理の不徹底による損害の責任はユーザーおよび法人が負うものとし、当社は一切の責任を負いません。またユーザーおよび法人は自己の登録情報が第三者によって不正利用されていることを知った場合は、ただちに当社にその旨を連絡し、当社の指示に従うものとします。なお、ユーザーおよび法人は、同連絡等を遅滞したことにより当社に生じるすべての損害等を賠償する義務を負うものとします。
  
  5項
  ユーザーが従前のユーザー自身による契約から、法人契約に変更する場合、従前のユーザーの翻訳回数等を引き継ぐか否かは、個別の取り決めによるものとし、法人契約以降は、法人契約を優先するものとします。
  
  6項
  法人契約において個別の取り決めがある場合、指定された者が管理画面にて利用者の情報、翻訳履歴等を確認することができるものとします。
  
  
  第5条（サービス規定）
  
  1項
  本サービスは、インターネットと当社の指定するブラウザを使用し、提供するものです。本サービスの利用にあたっては、パソコン又はスマートフォンなどのハードウェア等が必要です。インターネット環境、ハードウェアはユーザーおよび法人が用意するものとします。
  
  2項
  ユーザーおよび法人は当社が別途定める利用料金を支払うことで、選択した本サービスのプランの利用回数内で、サービスを利用することができます。
  
  3項
  ユーザーおよび法人が前項による支払いを行った後、サブスクリプション型プランにおいて次回の支払日までに第7条に定める解約手続きを行わない場合は、本サービスの利用を継続する意思があるものとみなし、同料金にて本サービスは継続的に更新されるものとします。なお、法人契約の場合は、個別の取り決めを優先するものとします。
  
  4項
  アカウントを削除する場合、翻訳回数が残っていてもアカウント削除時点で失効するものとします。なお、翻訳回数を全て使えなかった場合であっても、当社は翻訳回数の買取および返金は行いません。
  
  5項
  当社は、品質向上と不正利用防止のために、サービス利用で得られた情報を記録します。この情報には、個人情報が含まれる場合がありますが、当該情報はユーザーまたは法人へのサービス提供のみに利用します。
  
  6項
  有料プランを契約のユーザーまたは法人の翻訳結果が当社に起因する事由（ただし、第 10条に定める免責事項を除く）により不十分であった場合には、該当する回数分の翻訳回数をアカウントに付加致します。
  
  7項
  当社は，事前に通知の上で，本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし，これによってユーザーおよび法人に生じた損害について一切の責任を負いません。
  
  
  第6条（禁止行為）
  
  1項
  ユーザーおよび法人は、本サービスの利用に際して、以下に定める行為、またはそのおそれがある行為を行ってはなりません。
  ・本サービスを利用する権利を他者に譲渡、使用、売買すること。
  ・本サービスまたは当社の名誉、信用を失墜・毀損させる行為。
  ・違法行為、公序良俗に反する行為。
  ・虚偽の情報を登録する行為。
  ・本サービスの運用を妨げる行為。
  ・本サービスに関連して営利を目的とする行為、およびその準備に利用する行為。
  ・他の会員または当社若しくは第三者に不利益、損害を与える行為。
  ・犯罪行為および犯罪行為に結びつく行為。
  ・一つのアカウントを複数人で利用する行為（法人契約の場合を除く）。
  ・複数のアカウントを登録する行為。
  ・翻訳回数を不正に利用する行為。
  ・本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為
  ・本サービスを利用して翻訳するファイルの著作権者の権利を侵害する行為
  ・故意にサーバーに負荷をかける行為
  ・論文以外の文書など、本サービスの利用目的から逸脱するファイルを翻訳にかける行為
  ・その他、当社が不適当と判断する行為。
  
  2項
  ユーザーおよび法人が前項に定める行為を行った場合、当社は本サービスの登録を取り消すと共に、当社が何らかの損害を被った場合は、損害賠償請求、その他法的処置をとります。なお、前項に定める行為による賠償責任は本サービスの解約後も有効とします。
  
  
  第7条（解約・退会）
  
  ユーザーおよび法人は，当社の定める解約手続により，有料サービスを解約できるものとします。尚、ユーザーおよび法人は本サービスの登録の削除を希望する場合には、別途カスタマーサービスに依頼するものとし、登録削除により本サービスの一切の権利は失われ、当社に対して何らの請求をすることができないものとします。なお、法人契約の場合は、個別の取り決めを優先するものとします。
  
  
  第8条（サービスの中断・終了）
  
  当社は、以下のいずれかの事由があると判断した場合，事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとし、これによりユーザー、法人または第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。
  ・本サービスにかかるコンピュータシステムの保守点検または更新を行う場合
  ・地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合
  ・コンピュータまたは通信回線等が事故により停止した場合
  ・その他，当社が本サービスの提供が困難と判断した場合
  
  
  第9条（情報配信）
  
  当社はユーザーおよび法人に対して電子メールによる通知、広告およびアンケート等を実施できるもの
  とします。
  
  
  第10条（保証の否認および免責事項）
  
  1項
  当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
  
  ２項
  当社は，下記に定める事項に起因または関連してユーザーまたは法人に生じたあらゆる損害について故意または重過失が無い限り責任を負いません。ただし，本サービスに関する当社とユーザーおよび法人との間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
  ・本サービスを利用したこと、または利用ができなかったこと。
  ・ユーザーおよび法人の送信やデータへの不正アクセスや不正な改変がなされたこと。
  ・本サービスによる翻訳結果の内容。
  
  3項
  当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーおよび法人に生じた損害の賠償は，ユーザーおよび法人から当該損害が発生した月に受領した利用料の額を上限とします。
  
  4項
  当社は，本サービスに関して，ユーザーおよび法人と他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。
  
  5項
  本サービスに関する当社からの主な連絡手段は電子メールまたは本サイトとし、ユーザーおよび法人は当社からの電子メールを受信して、または本サイトにて確認するものとします。ユーザーおよび法人が確認を怠ったことにより生じるユーザーおよび法人の不利益には、当社は賠償する義務を一切負わないものとします。
  
  6項
  当社は、ユーザーおよび法人が本サービス利用時にコンピュータウィルス感染等により発生した損害と、本サービスに使用ファイルによって発生したいかなる損害も、当社に故意または重過失がない限り賠償する義務を一切負わないものとします。
  
  
  第11条（著作権および所有権）
  
  本サービスに関する商標、ロゴマークおよび記載等についての著作権、所有権は全て当社に帰属します。これらをユーザーおよび法人が当社に無断で使用することを禁じます。また、本サービス内でユーザーおよび法人が入力・編集した著作物の権利は著作者の定めに準じるものとし、その利用について当社は一切の責任を負わないものとします。
  
  
  第12条（準拠法および専属的合意管轄裁判所）
  本規約の準拠法は日本法とします。また、本サービスのご利用に関するすべての紛争については、名古屋地方裁判所をもって第一審における専属管轄裁判所とします。
  
  
  2021年7月20日改定
  2021年1月16日制定
  `;
}
