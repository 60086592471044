<template v-slot:unauthenticated>
  <form @submit="onSubmit" v-ga-track="{ action: 'submit-registeration' }">
    <div>
      <div v-if="state.instruction">
        <Instruction
          @close="onAlertClose"
          position="relative"
          :level="state.instruction.level"
          :closable="state.instruction.level === 'alert'"
        >
          <p v-html="state.instruction.message"></p>
        </Instruction>
      </div>
      <div class="uk-card-body">
        <div>
          <fieldset class="uk-fieldset">
            <FieldLabel title="ユーザ名" :required="true" />
            <input
              class="uk-input"
              type="text"
              name="username"
              :readonly="state.confirmed"
              v-model="username"
              v-clear-instruction
            />
            <FieldError name="username" />
            <FieldLabel title="メールアドレス" :required="true" />
            <input
              class="uk-input"
              type="text"
              name="email"
              v-model="email"
              :readonly="state.confirmed"
              v-clear-instruction
            />
            <FieldError name="email" />
            <FieldLabel title="パスワード" :required="true" />
            <input
              class="uk-input"
              type="password"
              name="password"
              :readonly="state.confirmed"
              v-model="password"
              v-clear-instruction
            />
            <FieldError name="password" />
            <div class="col2">
              <div>
                <FieldLabel title="姓" />
                <input
                  class="uk-input"
                  type="text"
                  name="last_name"
                  :readonly="state.confirmed"
                  v-model="last_name"
                  v-clear-instruction
                />
                <FieldError name="last_name" />
              </div>
              <div>
                <FieldLabel title="名" />
                <input
                  class="uk-input"
                  type="text"
                  name="first_name"
                  :readonly="state.confirmed"
                  v-model="first_name"
                  v-clear-instruction
                />
                <FieldError name="first_name" />
              </div>
            </div>
            <FieldLabel title="会社名・学校名" :required="true" />
            <input
              class="uk-input"
              type="text"
              name="belongs_to"
              :readonly="state.confirmed"
              v-model="belongs_to"
              v-clear-instruction
            />
            <FieldError name="belongs_to" />
            <FieldLabel title="住所" />
            <input
              class="uk-input"
              type="text"
              name="address"
              v-model="address"
              :readonly="state.confirmed"
              v-clear-instruction
            />
            <FieldError name="address" />
          </fieldset>

          <!--　ココを作業中　-->
          <FieldLabel class="termsTitle" title="利用規約" />
          <div class="termsBody">
            {{ termsText }}
          </div>
          <!----------------->
        </div>
      </div>
      <div class="uk-card-footer" :class="{ confirmed: state.confirmed }">
        <div v-if="!state.confirmed" />
        <button
          type="submit"
          :disabled="isSubmitting"
          class="uk-button uk-button-primary uk-margin-small-top"
          v-if="!state.confirmed"
          v-clear-instruction
          v-ga-track="{ action: 'confirm-registration-form' }"
        >
          確認
        </button>
        <button
          type="button"
          :disabled="isSubmitting"
          class="uk-button uk-button-default uk-margin-small-top"
          v-if="state.confirmed && !state.submitted"
          @click="onBackButtonClick"
          v-clear-instruction
        >
          戻る
        </button>
        <button
          type="submit"
          :disabled="isSubmitting"
          class="uk-button uk-button-primary uk-margin-small-top"
          v-if="state.confirmed && !state.submitted"
          v-clear-instruction
        >
          登録
        </button>
      </div>
    </div>
  </form>
</template>
<style lang="scss" scoped>
@include public-form-card;
@include public-form-card-footer;
@include form-input;
@include form-col2;
input[readonly] {
  border: 0;
}
.uk-card-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.termsTitle {
  margin: 8px 0px;
  padding-top: 32px;
}
.termsBody {
  height: 300px;
  width: 100%;
  overflow: scroll;
  text-align: left;
  white-space: pre-wrap;
  font-size: 12px;
  border: 1px solid #e5e5e5;
}
</style>
<script lang="ts">
import { defineComponent, reactive, nextTick } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import { UserApi } from "../../autogen";
import FieldLabel from "../atoms/FieldLabel.vue";
import FieldError from "../atoms/FieldError.vue";
import Instruction from "../atoms/Instruction.vue";
import { useI18n } from "vue-i18n";
import { Instruction as InstructionType } from "../../types";
import { useTerms } from "../compositions/useTerms";

type State = {
  instruction?: InstructionType;
  confirmed: boolean;
  submitted: boolean;
};
export default defineComponent({
  components: { FieldLabel, FieldError, Instruction },
  setup() {
    const state: State = reactive({ confirmed: false, submitted: false });
    const { t } = useI18n();
    const { handleSubmit, isSubmitting } = useForm<{
      email: string;
      password: string;
      belongs_to: string;
      username: string;
      first_name?: string;
      last_name?: string;
      address?: string;
    }>({
      validationSchema: yup.object({
        username: yup
          .string()
          .required(t("required", { field: "ユーザ名" }))
          .matches(/^[a-zA-Z0-9]{5,}$/, t("username")),
        email: yup
          .string()
          .email(t("email"))
          .required(t("required", { field: "メールアドレス" }))
          .matches(/^[^+]*$/, t("email")),
        password: yup
          .string()
          .required(t("required", { field: "パスワード" }))
          .matches(
            /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,255}$/,
            t("password")
          ),
        belongs_to: yup
          .string()
          .required(t("required", { field: "会社名・学校名" })),
        first_name: yup.string(),
        last_name: yup.string(),
        address: yup.string(),
      }),
    });
    const { value: username } = useField("username");
    const { value: email } = useField("email");
    const { value: password } = useField("password");
    const { value: first_name } = useField("first_name");
    const { value: last_name } = useField("last_name");
    const { value: belongs_to } = useField("belongs_to");
    const { value: address } = useField("address");
    function instruct(confirmed: boolean, instruction?: InstructionType) {
      window.scrollTo(0, 0);
      state.confirmed = confirmed;
      state.instruction = instruction;
    }
    const onSubmit = handleSubmit(async (values) => {
      if (!state.confirmed) {
        instruct(true, {
          level: "confirm",
          message: "登録内容を確認してください",
          timeout: false,
        });
        window.scrollTo(0, 0);
      } else {
        state.instruction = undefined;
        nextTick(async () => {
          try {
            await new UserApi().register({ registerRequest: values });
            state.instruction = {
              level: "info",
              message: "入力されたメールアドレスに認証メールを送信しました",
            };
            state.submitted = true;
          } catch (err) {
            if (err.response && err.response.status === 409) {
              const message = err.response.data.detail
                .map((d: { msg: string }) => t(d.msg))
                .join("<br/>");
              state.instruction = { level: "alert", message };
            } else {
              state.instruction = { level: "alert", message: err.message };
            }
          }
          window.scrollTo(0, 0);
        });
      }
    });
    const onAlertClose = () => {
      state.instruction = undefined;
    };
    const onBackButtonClick = () => {
      instruct(false);
    };
    const terms = useTerms();
    return {
      termsText: terms.text,
      state,
      onSubmit,
      isSubmitting,
      onAlertClose,
      username,
      email,
      password,
      first_name,
      last_name,
      belongs_to,
      address,
      onBackButtonClick,
    };
  },
});
</script>
