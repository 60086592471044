<template>
  <div uk-sticky="top: 0">
    <nav class="uk-navbar-container uk-navbar-transparent" uk-navbar>
      <div class="uk-navbar-left">
        <a href="https://1paper.jp"
          ><img src="@/assets/logo.png" class="logo"
        /></a>
      </div>
      <div class="uk-navbar-right">
        <ul class="uk-subnav uk-subnav-pill collapsed" uk-margin>
          <li>
            <a href="#"><span uk-icon="icon:  more"></span></a>
            <div uk-dropdown="mode: click;" ref="dropdownRef">
              <ul class="uk-nav uk-dropdown-nav">
                <li>
                  <!-- eslint-disable-next-line vue/no-deprecated-v-on-native-modifier -->
                  <router-link @click.native="onRouterLinkClick" to="/"
                    >マイページ</router-link
                  >
                </li>
                <template v-if="isAdmin">
                  <li>
                    <!-- eslint-disable-next-line vue/no-deprecated-v-on-native-modifier -->
                    <router-link
                      @click.native="onRouterLinkClick"
                      to="/enterprise"
                      >管理画面</router-link
                    >
                  </li>
                </template>
                <li>
                  <!-- eslint-disable-next-line vue/no-deprecated-v-on-native-modifier -->
                  <router-link @click.native="onRouterLinkClick" to="/usage"
                    >利用履歴</router-link
                  >
                </li>
                <li>
                  <!-- eslint-disable-next-line vue/no-deprecated-v-on-native-modifier -->
                  <router-link @click.native="onRouterLinkClick" to="/plan"
                    >プラン</router-link
                  >
                </li>
                <li>
                  <!-- eslint-disable-next-line vue/no-deprecated-v-on-native-modifier -->
                  <router-link @click.native="onRouterLinkClick" to="/profile"
                    >プロフィール</router-link
                  >
                </li>
                <li>
                  <a href="javascript:void(0);" @click="onInquiryButtonClick"
                    >お問い合わせ</a
                  >
                </li>
                <li>
                  <a href="javascript:void(0);" @click="onLogoutButtonClick"
                    >ログアウト</a
                  >
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <ul class="uk-navbar-nav expanded">
          <li>
            <router-link to="/">マイページ</router-link>
          </li>
          <template v-if="isAdmin">
            <li>
              <router-link to="/enterprise">管理画面</router-link>
            </li>
          </template>
          <li><router-link to="/usage">利用履歴</router-link></li>
          <li><router-link to="/plan">プラン</router-link></li>
          <li><router-link to="/profile">プロフィール</router-link></li>
          <li>
            <a href="javascript:void(0);" @click="onInquiryButtonClick"
              >お問い合わせ</a
            >
          </li>
          <li>
            <a href="javascript:void(0);" @click="onLogoutButtonClick"
              >ログアウト</a
            >
          </li>
        </ul>
      </div>
    </nav>
    <div uk-modal ref="inquiryModalRef">
      <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <InquiryForm
          @submitted="onInquirySubmitted"
          :key="state.inquiryFormKey"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
[uk-sticky] {
  background-color: #fff;
  padding: 0 $spacing-medium;
}
.collapsed {
  display: none;
}
@media screen and (max-width: 896px) {
  .expanded {
    display: none;
  }
  .collapsed {
    display: flex;
    align-items: center;
    height: 100%;
  }
  [uk-sticky] {
    padding: $spacing-small;
  }
}
</style>
<script lang="ts">
import { defineComponent, ref, reactive, onBeforeMount } from "vue";
import UIkit from "uikit";
import { MyApi } from "../../autogen";
import Store from "../../store";
import { useRouter } from "vue-router";
import InquiryForm from "../molecules/InquiryForm.vue";
import { useApp } from "../compositions/useApp";
type State = { inquiryFormKey: number };
export default defineComponent({
  components: { InquiryForm },
  setup() {
    const dropdownRef = ref<HTMLElement>();
    const inquiryModalRef = ref<HTMLElement>();
    const router = useRouter();
    const { userId, isAdmin } = useApp();

    onBeforeMount(() => {
      // get role in token
      const accessToken = Store.getInstance().getAccessToken();
      if (accessToken) {
        const base64Url = accessToken.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const token = JSON.parse(
          decodeURIComponent(escape(window.atob(base64)))
        );
        userId.value = token.user_id;
        isAdmin.value = token.role === "admin";
      }
    });

    const onRouterLinkClick = () => {
      if (dropdownRef.value) {
        // @ts-ignore
        UIkit.dropdown(dropdownRef.value).hide(0);
      }
    };
    const onLogoutButtonClick = async () => {
      try {
        await new MyApi().logout({});
        Store.getInstance().removeAuth();
        router.push("/login");
      } catch (err) {
        console.error(err);
      }
    };
    const onInquiryButtonClick = () => {
      if (inquiryModalRef.value) {
        state.inquiryFormKey = Date.now();
        UIkit.modal(inquiryModalRef.value).show();
      }
    };
    const onInquirySubmitted = () => {
      if (inquiryModalRef.value) {
        UIkit.modal(inquiryModalRef.value).hide();
      }
    };
    const state: State = reactive({ inquiryFormKey: 0 });
    return {
      isAdmin,
      onRouterLinkClick,
      dropdownRef,
      onLogoutButtonClick,
      onInquiryButtonClick,
      inquiryModalRef,
      onInquirySubmitted,
      state,
    };
  },
});
</script>
