<template>
  <div
    ref="alertRef"
    :class="{
      'uk-alert-danger': level === 'alert',
      'uk-alert-success': level === 'info',
      'uk-alert-warning': level === 'confirm',
    }"
    class="instruction-container"
    uk-alert
    :style="{ position: position || 'absolute' }"
  >
    <a v-if="closable" class="uk-alert-close" uk-close></a>
    <slot />
  </div>
</template>
<style lang="scss" scoped>
.instruction-container {
  text-align: left;
  top: 0;
  right: 0;
  left: 0;
  opacity: 0;
  background-color: transparent;
  border: 1px solid;
  animation: fadeIn 0.5s forwards;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 0.9;
    }
  }
}
</style>
<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import UIkit from 'uikit';
export default defineComponent({
  props: {
    position: {
      type: String,
    },
    level: {
      type: String,
    },
    timeout: {
      type: Number,
      default: 5000,
    },
    closable: {
      type: Boolean,
      default: true,
    },
  },
  setup(_props, context) {
    const alertRef = ref<HTMLElement>();
    onMounted(() => {
      if (_props.level !== 'alert') {
        if (_props.closable) {
          setTimeout(() => {
            if (alertRef.value) {
              UIkit.alert(alertRef.value).close();
            }
          }, _props.timeout);
        }
      }
      // @ts-ignore
      UIkit.util.on('[uk-alert]', 'hide', () => {
        context.emit('close');
      });
    });
    return { alertRef };
  },
});
</script>
