
import { defineComponent, ref, reactive, onBeforeMount } from "vue";
import UIkit from "uikit";
import { MyApi } from "../../autogen";
import Store from "../../store";
import { useRouter } from "vue-router";
import InquiryForm from "../molecules/InquiryForm.vue";
import { useApp } from "../compositions/useApp";
type State = { inquiryFormKey: number };
export default defineComponent({
  components: { InquiryForm },
  setup() {
    const dropdownRef = ref<HTMLElement>();
    const inquiryModalRef = ref<HTMLElement>();
    const router = useRouter();
    const { userId, isAdmin } = useApp();

    onBeforeMount(() => {
      // get role in token
      const accessToken = Store.getInstance().getAccessToken();
      if (accessToken) {
        const base64Url = accessToken.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const token = JSON.parse(
          decodeURIComponent(escape(window.atob(base64)))
        );
        userId.value = token.user_id;
        isAdmin.value = token.role === "admin";
      }
    });

    const onRouterLinkClick = () => {
      if (dropdownRef.value) {
        // @ts-ignore
        UIkit.dropdown(dropdownRef.value).hide(0);
      }
    };
    const onLogoutButtonClick = async () => {
      try {
        await new MyApi().logout({});
        Store.getInstance().removeAuth();
        router.push("/login");
      } catch (err) {
        console.error(err);
      }
    };
    const onInquiryButtonClick = () => {
      if (inquiryModalRef.value) {
        state.inquiryFormKey = Date.now();
        UIkit.modal(inquiryModalRef.value).show();
      }
    };
    const onInquirySubmitted = () => {
      if (inquiryModalRef.value) {
        UIkit.modal(inquiryModalRef.value).hide();
      }
    };
    const state: State = reactive({ inquiryFormKey: 0 });
    return {
      isAdmin,
      onRouterLinkClick,
      dropdownRef,
      onLogoutButtonClick,
      onInquiryButtonClick,
      inquiryModalRef,
      onInquirySubmitted,
      state,
    };
  },
});
