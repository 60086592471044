
import { defineComponent, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { MyApi } from '../../autogen';
import FieldLabel from '../atoms/FieldLabel.vue';
import FieldError from '../atoms/FieldError.vue';
import { useI18n } from 'vue-i18n';
import Instruction from '../atoms/Instruction.vue';
import { Instruction as InstructionType } from '../../types';
type State = { instruction?: InstructionType; reset: boolean };
export default defineComponent({
  components: { FieldLabel, FieldError, Instruction },
  setup() {
    const router = useRouter();
    const { t } = useI18n();
    const { handleSubmit, isSubmitting } = useForm({
      validationSchema: yup.object({
        password: yup
          .string()
          .required(t('required', { field: 'パスワード' }))
          .matches(
            /^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,255}$/,
            t('password')
          ),
      }),
    });
    const state: State = reactive({ reset: false });
    function clearState() {
      state.instruction = undefined;
    }
    const { value: password } = useField('password');
    const onSubmit = handleSubmit(async (values) => {
      clearState();
      try {
        await new MyApi().reset({
          authorization: new URLSearchParams(location.search).get('token')!,
          resetRequest: { password: values.password! },
        });
        state.instruction = {
          level: 'info',
          message: 'パスワードのリセットが完了しました',
        };
        state.reset = true;
      } catch (err) {
        state.instruction = {
          level: 'alert',
          message:
            'パスワードのリセットに失敗しました<br/>URLが不正または期限切れです',
        };
        state.reset = false;
      }
    });
    const onAlertClose = () => {
      clearState();
    };
    const onLoginButtonClick = () => {
      router.push('/login');
    };
    return {
      t,
      state,
      onSubmit,
      isSubmitting,
      password,
      onLoginButtonClick,
      onAlertClose,
    };
  },
});
