<template>
  <div class="container">
    <UsageProgress
      class="progress"
      :value="enterprise.consumedUsages.value"
      :max="enterprise.contractedUsages.value"
    />
    <MembersTable class="table" :members="enterprise.members.value" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onBeforeMount } from "vue";
import UsageProgress from "../atoms/UsageProgress.vue";
import MembersTable from "../atoms/MembersTable.vue";
import { useEnterprise } from "../compositions/useEnterprise";

export default defineComponent({
  components: { MembersTable, UsageProgress },
  setup() {
    const enterprise = useEnterprise();

    // 初期化処理
    onBeforeMount(async () => {
      await enterprise.getMembers();
      await enterprise.getUsages();
    });

    return {
      enterprise,
    };
  },
});
</script>

<style scoped>
.container {
  display: table;
  box-sizing: content-box;
  width: 90vw;
}
.progress {
  margin-top: 15px;
}
.table {
  margin-top: 15px;
}
progress {
  margin: 0px !important;
}
</style>
