
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useUsage } from "../compositions/useUsage";
export default defineComponent({
  props: {
    // 読み込む方向
    // 'latest' ... 新しい方向
    // 'oldest' ... 古い方向
    direction: {
      type: String,
      required: true,
      validator: (data: string) => {
        return data === "latest" || data === "oldest";
      },
    },
  },
  setup(props) {
    const route = useRoute();
    const usage = useUsage();
    let isLoading = ref<boolean>(false);

    // 論文の取得処理
    const onClicked = async () => {
      if (isLoading.value) return;
      // 取得ページ番号の制御
      let page = props.direction === "latest" ? 0 : usage.getCurrentPage() + 1;
      isLoading.value = true;

      // エンタープライズ時用の情報を取得
      const isEnterprise = route.query.mode === "enterprise";
      const mode = isEnterprise ? "enterprise" : "my";
      let userId: number | null = null;
      if (route.query.userId && !Array.isArray(route.query.userId)) {
        userId = parseInt(route.query.userId);
      }

      // 追加ロード
      await usage.load(mode, userId, page);
      isLoading.value = false;
    };

    return {
      usage,
      isLoading,
      onClicked,
    };
  },
});
