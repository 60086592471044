
import { defineComponent, reactive, onBeforeMount } from 'vue';
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { InquiryRequest, InquiryTypeEnum, UserApi, MyApi } from '../../autogen';
import FieldLabel from '../atoms/FieldLabel.vue';
import FieldError from '../atoms/FieldError.vue';
import Instruction from '../atoms/Instruction.vue';
import { useI18n } from 'vue-i18n';
import { Instruction as InstructionType } from '../../types';
import Store from '../../store';

type State = {
  instruction?: InstructionType;
};
export default defineComponent({
  components: { FieldLabel, FieldError, Instruction },
  props: {
    type: {
      type: String,
      default: InquiryTypeEnum.AboutTranslationError,
    },
    usageId: {
      type: Number,
    },
  },
  setup(props, context) {
    const inquiryTypes = {
      [InquiryTypeEnum.AboutTranslationError]: '翻訳について',
      [InquiryTypeEnum.AboutEnterprisePlan]: 'エンタープライズプランについて',
      [InquiryTypeEnum.AboutOthers]: 'その他',
    };
    const state: State = reactive({});
    const { t } = useI18n();
    const { handleSubmit, isSubmitting, resetForm } = useForm<{
      email: string;
      type: string;
      comment: string;
    }>({
      validationSchema: yup.object({
        email: yup
          .string()
          .email(t('email'))
          .required(t('required', { field: 'メールアドレス' })),
        type: yup
          .string()
          .required(t('required', { field: 'お問い合わせ種別' })),
        comment: yup
          .string()
          .required(t('required', { field: 'お問い合わせ内容' })),
      }),
    });
    const { value: email } = useField('email');
    const { value: itype } = useField('type');
    const { value: comment } = useField('comment');
    onBeforeMount(async () => {
      let values = { type: props.type, email: '', comment: '' };
      if (Store.getInstance().getAuth()) {
        const response = await new MyApi().getMyProfile({});
        values.email = response.data.profile.email;
      }
      resetForm({ values });
    });
    const onSubmit = handleSubmit(async (values) => {
      await new UserApi().inquiry({
        inquiryRequest: Object.assign(
          { usage_id: props.usageId },
          values
        ) as InquiryRequest,
      });
      context.emit('submitted');
    });
    const onAlertClose = () => {
      state.instruction = undefined;
    };
    return {
      state,
      onSubmit,
      isSubmitting,
      onAlertClose,
      email,
      itype,
      comment,
      inquiryTypes,
    };
  },
});
